<template>
    <transition name="fade">
      <v-alert
        v-if="api.isSuccesful"
        dense
        border="left"
        type="success">
        {{api.success}}
      </v-alert>
    </transition>
  </template>
  
  <script>
    export default {
      props:['api']
    }
  </script>
  