<template>
    <v-container>
        <v-row class="mt-3">
            <v-col align="center" justify="center" class="mx-auto"
            cols="12"
            sm="8">
            <v-col align="start" justify="start">
                <v-btn 
                class="mb-3"
                color="primary"
                @click="closeDetail">
                    <v-icon>
                        mdi-arrow-left-bold
                    </v-icon>
                </v-btn>  
            </v-col>
            <v-card class="pa-3"
            elevation-1>
               
                <v-toolbar class="white--text text-h5 mb-4"
                color="secondary">
                    Update detail
                </v-toolbar>
                <ASuccessThree
                :api="this.api"/>
                <v-col>
                    <div class="d-flex justify-space-between">
                        <v-text-field class="pr-5"
                        v-model="user.fname"
                        label="First name">
                        </v-text-field>
                        <v-text-field
                        v-model="user.lname"
                        label="Last name">
                        </v-text-field>
                    </div>
                    <v-text-field
                    v-model = user.mobile
                    label="Mobile phone">
                    </v-text-field>
                    <v-text-field
                    v-model = user.email
                    label="Email">
                    </v-text-field>
                    <v-select
                    v-model = user.role
                    :items = roleOptions
                    label = "Role">
                    </v-select>
                </v-col>
                <div class="d-flex justify-space-between">
                    <v-btn @click="deleteUser" color="red" plain :loading="this.api.isLoading">
                        Delete
                    </v-btn>
                    <div class="d-flex justify-end">
                        <v-btn
                        color="primary"
                        plain>
                            Cancel
                        </v-btn>
                        <v-btn
                        @click = "submit"
                        :loading = "this.api.isLoading"
                        color="primary">
                            Submit
                        </v-btn>
                    </div>
                </div>
            </v-card>
        </v-col>

        </v-row>
    </v-container>
</template>
<script>
import ASuccessThree from '../../components/common/ASuccessThree.vue'
export default{
    components:{
        ASuccessThree,
    },
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.status == "success") {
                this.api.isSuccesful = true;
                this.api.isError = false;
                this.api.isLoading = false;
                
                return resp.data;
            }
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            if (this.$store.getters.getUser == null) {
                this.$router.push({ name: "PageErrorNotFound" });
            }
            this.user = this.$store.getters.getUser;
            this.user.role = this.user.role.charAt(0).toUpperCase() + this.user.role.slice(1);
            
        },
        closeDetail(){
            this.$store.dispatch("clearCompany");
            this.$router.go(-1);
        },
        deleteUser()
        {
            let tempUser = this.$_.clone(this.user);
            tempUser.role = tempUser.role.charAt(0).toLowerCase() + tempUser.role.slice(1);
            tempUser.role = tempUser.role.replaceAll(' ','_');
            this.api.method="delete";
            this.api.url = process.env.VUE_APP_SERVER_API+"/user/"+tempUser.id;
            this.$api.fetch(this.api).then(()=>{
                this.$router.push({name:'PageAdminSalesperson',params:{"staff":tempUser.role}});
            });
        },
        update() {
            let tempUser = this.$_.clone(this.user);
            let tempApi = this.$_.clone(this.api);
            tempUser.role = tempUser.role.charAt(0).toLowerCase() + tempUser.role.slice(1);
            tempUser.role = tempUser.role.replaceAll(' ','_');

            tempApi.method = "put";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/user/staff/" + tempUser.id;
            tempApi.params = tempUser;
            return tempApi;
        },
        submit() {
           
            let updateApi = this.update();
            this.$api.fetch(updateApi).then(() => {
            this.api.success = "Succesfully update the staff";
            });
        }
    },
    data: () => ({
        user: {
            fname: "irfan",
            lname: "hafiz",
            mobile_phone: "01169912862",
            email: "irfan123@taxpod.my",
            role: "Salesperson",
        },
        roleOptions: ["Vision admin", "Customer service", "Operation", "Salesperson", "Sales leader"],
        api: {
            isSuccesful: false,
            isError: false,
            isLoading: false,
            error: null,
            success: null,
            url: null
        }
    }),
}
</script>